<template>
  <div>
    <ActionLink class="mt-2 text-xs" @click="show = true">
      {{
        address
          ? $t("shared-order-approval.step-2.update_delivery_address")
          : $t("shared-order-approval.step-2.add_delivery_address")
      }}
    </ActionLink>

    <grow>
      <div
        v-if="show"
        class="z-max fixed inset-0 flex h-screen overflow-y-auto bg-black bg-opacity-60 backdrop-filter backdrop-blur-xs py-12"
      >
        <div class="relative m-auto z-max">
          <div class="max-w-xl w-full">
            <OnClickOutside
              ref="modalBox"
              class="relative bg-white px-16 py-14 rounded-xl overflow-hidden"
              @trigger="close"
            >
              <div class="absolute top-0 inset-x-0">
                <svg
                  class="w-full"
                  fill="none"
                  viewBox="0 0 500 78"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m390 38c-190 0-301 78-390 14v-52h500v61s-32-23-110-23z" fill="#f1f5f9" />
                </svg>
              </div>
              <form class="relative" @submit.prevent="handleSubmit">
                <div class="mb-8 space-y-1">
                  <h2 class="font-black text-3xl text-black">
                    {{
                      address
                        ? $t("shared-order-approval.step-2.update_delivery_address")
                        : $t("shared-order-approval.step-2.add_delivery_address")
                    }}
                  </h2>
                  <p class="text-sm text-gray-500">
                    {{
                      address
                        ? $t("shared-order-approval.step-2.update_delivery_address_explanation")
                        : $t("shared-order-approval.step-2.add_delivery_address_explanation")
                    }}
                  </p>
                </div>
                <div class="mb-10">
                  <AddressSelection
                    id="select-address"
                    v-model="form.id"
                    :label="$tc('forms.saved_addresses')"
                    :addresses="addresses"
                    @input="updateAddressFormValues"
                  />
                  <TextInput
                    v-model="form.company_name"
                    :label="$tc('forms.company')"
                    :error="errors.company_name"
                    :help-text="companyNameDisabled ? '' : $tc('checkout.company_field_help_text')"
                    :disabled="companyNameDisabled"
                  />
                  <div class="flex -mx-1">
                    <TextInput
                      v-model="form.recipient_first_name"
                      class="w-1/2 px-1"
                      :label="$tc('forms.recipient_first_name')"
                      required
                      :error="errors.recipient_first_name"
                      :show-error-tooltip="false"
                    />
                    <TextInput
                      v-model="form.recipient_last_name"
                      class="w-1/2 px-1"
                      :label="$tc('forms.recipient_last_name')"
                      :error="errors.recipient_last_name"
                      required
                      :show-error-tooltip="false"
                    />
                  </div>
                  <div class="flex -mx-1">
                    <TextInput
                      v-model="form.street"
                      class="w-1/2 px-1"
                      :label="$tc('forms.street')"
                      :error="errors.street"
                      :show-error-tooltip="false"
                      required
                    />
                    <TextInput
                      v-model="form.number"
                      class="w-1/4 px-1"
                      :label="$tc('forms.number')"
                      :error="errors.number"
                      :show-error-tooltip="false"
                      required
                    />
                    <TextInput
                      v-model="form.bus"
                      class="w-1/4 px-1"
                      :label="$tc('forms.bus')"
                      :error="errors.bus"
                    />
                  </div>
                  <div class="flex -mx-1">
                    <TextInput
                      v-model="form.zip_code"
                      class="w-1/2 px-1"
                      :label="$tc('forms.zipcode')"
                      :error="errors.zip_code"
                      :show-error-tooltip="false"
                      required
                    />
                    <TextInput
                      v-model="form.city"
                      class="w-1/2 px-1"
                      :label="$tc('forms.city')"
                      :error="errors.city"
                      :show-error-tooltip="false"
                      required
                    />
                  </div>
                  <SelectHtml
                    v-model="form.country"
                    id="country"
                    :label="$t('forms.country')"
                    :options="countriesData"
                    required
                    :help-text="
                      $t('project-vendors.shipping_limitation_notice', {
                        vendor: vendorNames,
                      })
                    "
                    :error="errors.country"
                  />
                </div>
                <div class="flex justify-center space-x-4">
                  <TertiaryButton @click="show = false">
                    {{ $t("general.cancel") }}
                  </TertiaryButton>
                  <PrimaryButton :busy="working" type="submit">{{
                    address ? $t("general.update") : $t("general.add")
                  }}</PrimaryButton>
                </div>
              </form>
            </OnClickOutside>
          </div>
        </div>
      </div>
    </grow>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import { OnClickOutside } from "@vueuse/components"
import { mapState, mapMutations } from "vuex"
import SelectHtml from "@/components/elements/inputs/SelectHtml.vue"
import Grow from "@/components/transitions/Grow.vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import AddressSelection from "@/Inertia/Pages/Checkout/FormElements/AddressSelection.vue"
import { errorToast, successToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import TextInput from "@/components/elements/inputs/TextInput.vue"
import ActionLink from "@/components/elements/links/ActionLink.vue"

export default {
  components: {
    Grow,
    SelectHtml,
    PrimaryButton,
    OnClickOutside,
    TertiaryButton,
    AddressSelection,
    TextInput,
    ActionLink,
  },
  props: {
    countries: Array,
    orderId: Number,
    addresses: Array,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  data() {
    return {
      form: {},
      show: false,
      errors: {},
      working: false,
      countriesData: this.countries.map(country => ({
        id: country.value,
        name: country.country,
      })),
    }
  },
  computed: {
    ...mapState("sharedOrder", ["settings", "order", "orderItems"]),
    address() {
      return this.order.address
    },
    vendorNames() {
      const names = []
      for (const item of this.orderItems) {
        const vendor = item.gift.vendor?.name
        if (vendor && !names.includes[vendor]) {
          names.push(vendor)
        }
      }
      return names.join(", ")
    },
    companyNameDisabled() {
      const address = this.addresses.find(address => address.id == this.form.id)
      if (!address) {
        return false
      }

      return !!address.company_entity_id
    },
  },
  watch: {
    show(val) {
      document.querySelector("#main").classList.toggle("overflow-y-auto", !val)
    },
  },
  beforeUnmount() {
    document.querySelector("#main").classList.toggle("overflow-y-auto", true)
  },
  created() {
    this.form = this.address || {}
  },

  methods: {
    ...mapMutations("sharedOrder", ["setState"]),
    handleSubmit() {
      this.working = true
      this.errors = {}

      const routeObj = new URL(
        this.zRoute("shared-order.update-address", {
          order: this.orderId,
        }),
      )

      const {
        bus,
        city,
        country,
        id,
        recipient_first_name,
        recipient_last_name,
        number,
        street,
        zip_code,
      } = this.form

      httpClient
        .post(routeObj.pathname + routeObj.search, {
          bus,
          city,
          country,
          id,
          recipient_first_name,
          recipient_last_name,
          number,
          street,
          zip_code,
        })
        .then(({ data }) => {
          this.setState({ attribute: "order", value: data })
          this.close()
          successToast({
            message: this.t("shared-order-approval.step-2.delivery-address-updated"),
          })
        })
        .catch(err => {
          if (err.response?.data?.errors) {
            const errors = {}
            Object.entries(err.response.data.errors).forEach(
              entry => (errors[entry[0]] = entry[1].pop()),
            )
            this.errors = errors
            errorToast({ message: this.t("shared-order-approval.step-2.errors-in-the-form") })
          } else {
            errorToast({
              message: this.t("shared-order-approval.step-2.failed-to-update-delivery-address"),
            })
          }
        })
        .finally(() => (this.working = false))
    },
    close() {
      this.show = false
    },
    updateAddressFormValues(id) {
      const address = this.addresses.find(address => address.id === id)

      if (!address) {
        this.form.id = null
        this.form.recipient_first_name = ""
        this.form.recipient_last_name = ""
        this.form.city = ""
        this.form.bus = ""
        this.form.zip_code = ""
        this.form.country = ""
        this.form.street = ""
        this.form.number = ""
        return
      }

      this.form.recipient_first_name = address.recipient_first_name
      this.form.recipient_last_name = address.recipient_last_name
      this.form.city = address.city
      this.form.bus = address.bus
      this.form.zip_code = address.zip_code
      this.form.country = address.country
      this.form.street = address.street
      this.form.number = address.number
    },
  },
}
</script>
