<template>
  <div class="pb-24">
    <div>
      <button
        type="button"
        class="flex items-center space-x-2 text-xs font-semibold text-pink-300 uppercase disabled:cursor-not-allowed"
        :disabled="working"
        @click="handleCancel"
      >
        <LongBackIcon class="w-6 h-6" />
        <span>{{ $t("shared-order-approval.back_to_order_overview") }}</span>
      </button>
    </div>

    <div class="mt-10">
      <p class="text-xs font-semibold tracking-wider text-pink-300 uppercase">
        {{ item.occasion.name[userLang] }}
      </p>
      <h3 class="text-2xl font-extrabold font-title text-black">
        {{ gift.name[userLang] }} -
        <span
          v-tooltip="{
            content: item.voucher_discount
              ? $t('shared-order-approval.discount_has_been_applied', {
                  discount: item.voucher_discount * 100,
                })
              : '',
          }"
        >
          <span
            class="font-title font-extrabold text-lg"
            :class="item.voucher_discount ? 'text-gray-300 line-through' : ''"
          >
            {{ currencyEuro(price) }}
          </span>
          <span v-if="item.voucher_discount" class="text-lg font-title font-extrabold ml-2">
            {{ currencyEuro(price * (1 - (item.voucher_discount || 0))) }}
          </span>
        </span>
      </h3>
    </div>

    <OrderItem minimal :item="item" />

    <div class="mt-10 border-t border-gray-200"></div>

    <div class="mt-10">
      <div class="space-y-7">
        <div class="grid grid-cols-5">
          <div class="col-span-3">
            <p class="font-bold font-title text-gray-600">
              {{ $t("shared-order-approval.display_logo") }}
            </p>
            <p class="mt-1 text-sm text-gray-400">
              {{ $t("shared-order-approval.display_logo_description_one") }}
              <br />
              {{ $t("shared-order-approval.display_logo_description_two") }}
            </p>
          </div>
          <div v-if="readOnly" class="col-span-2">
            <p v-if="metadata.include_logo">
              {{ $t("shared-order-approval.logo_will_be_included") }}
            </p>
            <p v-else>
              {{ $t("shared-order-approval.logo_will_not_be_included") }}
            </p>
          </div>
          <div v-else class="col-span-2 items-start place-content-start">
            <Toggle v-model="includeLogo" class="w-14" />
          </div>
          <template v-if="readOnly && metadata.logo">
            <div class="col-span-3"></div>
            <div class="col-span-2">
              <div class="flex items-center">
                <img
                  :src="metadata.logo"
                  alt="Included Logo"
                  class="flex-shrink-0 w-32 mr-4 rounded"
                />
              </div>
            </div>
          </template>
          <template v-if="includeLogo && !readOnly">
            <div class="col-span-3"></div>
            <div class="col-span-2">
              <div class="flex items-center">
                <div class="relative mr-4">
                  <img
                    v-if="logoImage"
                    :src="logoImage"
                    alt="Current Logo"
                    class="flex-shrink-0 w-32 rounded"
                  />

                  <PrimaryButton
                    v-if="canDeleteOrderItemLogo"
                    compact
                    class="absolute -top-3 -right-3 text-pink-300 border-pink-300 hover:bg-pink-200 hover:bg-opacity-50 pl-1 pr-1"
                    @click.stop="deleteLogo"
                  >
                    <TrashIcon class="w-4 h-4" />
                  </PrimaryButton>
                </div>
                <FileUploader
                  accept="image/png, image/jpeg, image/gif"
                  class="flex-1"
                  @input="handleLogoUpload"
                />
              </div>
            </div>
          </template>
        </div>
        <div v-if="directlyToRecipient && isDigital" class="grid grid-cols-5">
          <div class="col-span-3">
            <p class="font-bold font-title text-gray-600">
              {{ $t("shared-order-approval.preview_voucher_and_email") }}
            </p>
            <p class="max-w-sm mt-1 text-sm text-gray-400">
              {{ $t("shared-order-approval.preview_voucher_and_email_description") }}
            </p>
          </div>
          <div class="col-span-2">
            <div class="h-full flex-items-end">
              <div class="relative">
                <input
                  v-model="previewEmail"
                  :readonly="isSendingPreviewEmail"
                  class="w-full px-4 py-3 text-sm placeholder-gray-300 bg-white border border-gray-200 rounded focus:outline-none focus:border-pink-300 focus:ring-pink-300"
                  placeholder="example@domain.com"
                  type="email"
                />
                <button
                  :class="{
                    'bg-gray-300 focus:outline-none cursor-not-allowed': isSendingPreviewEmail,
                    'bg-blue-300': !isSendingPreviewEmail,
                  }"
                  class="absolute px-6 text-xs tracking-wider text-white uppercase transition rounded-r inset-y-1 right-1 hover:bg-opacity-80"
                  @click="sendPreviewEmail"
                >
                  {{
                    isSendingPreviewEmail
                      ? $t("shared-order-approval.sending")
                      : $t("shared-order-approval.send_example")
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="landingPage" class="grid grid-cols-5">
          <div class="col-span-3">
            <p class="font-bold font-title text-gray-600">
              {{ $t("shared-order-approval.preview_landing_page") }}
            </p>
            <p class="mt-1 text-sm text-gray-400">
              {{ $t("shared-order-approval.preview_landing_page_description") }}
            </p>
          </div>
          <div class="col-span-2">
            <a
              :href="landingPage"
              target="_blank"
              class="font-semibold text-pink-300 uppercase text-2xs"
              type="button"
            >
              {{ $t("shared-order-approval.view_landing_page") }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-10 border-t border-gray-200"></div>

    <div class="mt-10">
      <template v-if="!readOnly">
        <div>
          <h3 class="text-lg font-bold font-title text-gray-600">
            {{ $t("shared-order-approval.recipient_list") }}
          </h3>
          <p class="text-sm text-gray-400">
            {{ $t("shared-order-approval.recipient_list_description") }}
          </p>
        </div>

        <div v-if="settings.is_tenancy_enabled" class="mt-10">
          <h3 class="text-base font-bold font-title text-gray-600">
            {{ $t("general.recipient_type") }}
          </h3>
          <div class="mt-4">
            <div class="space-y-2">
              <ComponentTabs
                v-model="recipientType"
                :tabs="[
                  { label: $t('general.via_excel'), value: 'excel' },
                  {
                    label: $t('general.via_employee_list'),
                    value: 'employees',
                  },
                ]"
              />
            </div>
          </div>
        </div>

        <div v-if="isExcel" class="max-w-2xl mt-10 p-6 bg-gray-50 border border-gray-200">
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-base font-bold font-title text-gray-600">
                {{ $t("shared-order-approval.upload_through_file") }}
              </h3>
              <p class="text-sm text-gray-400">
                {{ $t("shared-order-approval.upload_description") }}
              </p>
            </div>
            <div>
              <a
                target="_blank"
                :href="item.excel_template_download_url"
                class="text-xs font-semibold text-pink-300 uppercase"
                >{{ $t("general.download_file") }}</a
              >
            </div>
          </div>

          <FileUploader
            :working="isImportingExcel"
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            class="mt-4"
            @input="handleExcelUpload"
          />
          <p class="mt-2 text-sm text-gray-400">
            {{ $t("shared-order-approval.excel_limitation_note") }}
          </p>

          <!-- Excel Upload Success -->
          <div v-if="showUploadSuccessMessage" class="mt-4">
            <p
              class="flex space-x-3 tracking-widest uppercase text-2xs"
              v-html="$t('orders.excel_upload_success_message')"
            ></p>
          </div>

          <!-- Excel Errors Section -->
          <small
            v-show="$store.state.product.errors.excelFile"
            class="block p-2 text-sm text-red-300"
          >
            {{
              $store.state.product.errors.excelFile
                ? $store.state.product.errors.excelFile[0]
                : null
            }}
          </small>
          <p
            v-if="excelUploadStatus === false"
            class="mt-4 tracking-widest uppercase text-2xs"
            v-html="$t('orders.excel_upload_error_message')"
          ></p>
          <div v-if="excelErrors.length" class="">
            <div v-show="excelErrors.length >= 7" class="flex items-center pt-4">
              <span class="mr-4 leading-none text-red-300"
                >{{ $t("forms.errors") }} ({{ excelErrors.length }})</span
              >
              <button
                class="inline-block px-2 py-1 text-xs font-semibold text-pink-300 uppercase transition-colors duration-300 ease-in-out border border-blue-200 rounded hover:bg-pink-300 hover:text-white group-hover:border-pink-300 hover:border-pink-300"
                type="button"
                @click="showAllErrors = !showAllErrors"
              >
                {{ showAllErrors ? $t("forms.hide") : $t("forms.show") }}
              </button>
            </div>
            <ul
              v-show="showAllErrors || excelErrors.length < 7"
              class="pt-4 mt-4 text-red-400 list-disc list-inside border-t border-gray-200"
            >
              <li v-for="(error, index) in excelErrors" :key="`error-${index}`" class="pt-1 pb-2">
                <small class="text-sm text-red-300">{{ error }}</small>
              </li>
            </ul>
          </div>
          <!-- End Excel Errors Section -->
        </div>

        <div v-if="isEmployeeList">
          <p class="mt-6 max-w-2xl text-sm text-gray-400">
            {{ $t("shared-order-approval.select-via-employees-explanation") }}
          </p>
        </div>
      </template>

      <div v-if="hasExampleForPreview" class="border-t border-b border-gray-200 py-10 my-10">
        <div>
          <p class="font-bold font-title text-gray-600">
            {{ $t("shared-order-approval.preview_voucher") }}
          </p>
          <p class="mt-1 text-sm text-gray-400">
            {{ $t("shared-order-approval.preview_voucher_description") }}
          </p>
        </div>
        <PrimaryButton class="mt-2" @click="generateExampleVoucher()">
          {{ $t("shared-order-approval.view_examples") }}
        </PrimaryButton>

        <ModalEmpty :show="previewModalOpen" @close="closePreviewModal">
          <div class="relative bg-white px-16 py-14 rounded-lg" style="width: 70vw">
            <button type="button" class="absolute right-5 top-5" @click="closePreviewModal">
              <XCircleSolidIcon class="w-7 h-7 text-gray-400" />
            </button>
            <div
              :style="{ height: isLoadingExampleVoucher ? '600px' : 'auto' }"
              :class="{
                'flex justify-center items-center': isLoadingExampleVoucher,
              }"
            >
              <div v-if="isLoadingExampleVoucher">
                <div class="text-center mb-3">
                  {{
                    $t("shared-order-approval.generating_for_the_recipient", {
                      to: exampleVoucherRecipient.to,
                    })
                  }}
                </div>
                <div class="w-10 h-10 animate-spin mx-auto">
                  <loading-icon />
                </div>
              </div>
              <div v-else class="p-3">
                <h3 class="text-2xl font-bold leading-6">
                  <span v-if="gift.is_kadonation_voucher">{{
                    $t("shared-order-approval.preview_the_vouchers")
                  }}</span>
                  <span v-if="gift.kadonation_product_type === 'kadonation-greeting-card'">{{
                    $t("shared-order-approval.preview_greeting_card")
                  }}</span>
                </h3>
                <div v-if="generatedPDFs.length > 1" class="mt-2 space-x-2 text-pink-300">
                  <a
                    v-for="file in generatedPDFs"
                    :key="file.key"
                    :class="{
                      'bg-pink-300 text-white py-1 px-2 rounded-sm': activePDF == file.file,
                    }"
                    class="bg-white hover:underline"
                    href="#"
                    @click.prevent="activePDF = file.file"
                    >{{ file.key }}</a
                  >
                </div>
                <div class="my-4">
                  <div class="w-full mt-6">
                    <embed alt="pdf" class="w-full" height="600" :src="activePDF" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalEmpty>
      </div>

      <div class="mt-10">
        <h3 v-if="isExcel" class="mb-4 text-base font-bold font-title text-gray-600">
          {{ $t("shared-order-approval.list_of_recipients") }}
        </h3>
        <div class="mb-8">
          <RecipientSelector
            ref="recipient-selector"
            :read-only="readOnly"
            :excel="isExcelList"
            :working="working"
            :has-example-for-preview="hasExampleForPreview"
            :employees-enabled="settings.is_tenancy_enabled && isEmployeeList"
            @confirm-selection="save"
            @cancel="handleCancel"
            @showPreview="generateExampleVoucher($event)"
          />
        </div>

        <div
          v-if="validationErrors.length"
          class="max-w-lg px-4 py-2 space-y-2 overflow-y-auto text-sm border rounded-lg"
          style="max-height: 380px"
        >
          <dl v-for="(row, i) in validationErrors" :key="`row-error-${i}`">
            <dt class="font-semibold">
              {{ $t("errors.error_on_row", { row: row.row }) }}
            </dt>
            <dd v-for="(error, index) in Object.values(row.error)" :key="`error-message-${index}`">
              <p class="pl-4">{{ error }}</p>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex"
import { DateTime } from "luxon"
import _ from "lodash"
import { useI18n } from "vue-i18n"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import { LoadingIcon, LongBackIcon, XCircleSolidIcon, TrashIcon } from "../icons"
import { FileUploader } from "../elements/file-uploader"
import RecipientSelector from "@/components/recipients/RecipientSelector.vue"
import ModalEmpty from "@/components/elements/modals/ModalEmpty.vue"
import OrderItem from "@/components/shared-order-approval/elements/OrderItem.vue"
import { currencyEuro } from "@/helpers/currency-helpers"
import { captureError } from "@/helpers/error-helpers"
import { errorToast, successToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import uuid from "@/helpers/uuid"
import Toggle from "../elements/toggles/Toggle.vue"
import ComponentTabs from "../elements/tabs/ComponentTabs.vue"

export default {
  components: {
    TrashIcon,
    Toggle,
    ComponentTabs,
    PrimaryButton,
    FileUploader,
    RecipientSelector,
    LongBackIcon,
    LoadingIcon,
    XCircleSolidIcon,
    ModalEmpty,
    OrderItem,
  },
  props: {
    readOnly: Boolean,
    logo: String,
  },
  setup() {
    const { t } = useI18n()
    return { t, currencyEuro }
  },

  data() {
    return {
      recipientType: "excel",
      file: null,
      includeLogo: false,
      logoFile: null,
      logoFilePreview: "",
      isImportingExcel: false,
      showAllErrors: false,
      overwrite: false,
      previewModalOpen: false,
      working: false,
      metadata: {},
      previewEmail: null,
      isSendingPreviewEmail: false,
      excelPath: "",
      landingPage: null,
      isLoadingExampleVoucher: false,
      exampleVoucherRecipient: null,
      activePDF: null,
      exampleVouchers: [],
      showUploadSuccessMessage: false,
      validationResponse: [],
      isExcelList: false,
    }
  },

  created() {
    // Set headers for Shared Order Page
    httpClient.defaults.headers.common["X-Original-Sender"] = window.location.href

    if (this.personalizationDataForCurrentItem) {
      this.includeLogo = this.personalizationDataForCurrentItem.includeLogo
      this.recipientType = this.personalizationDataForCurrentItem.recipientType
    } else {
      this.metadata = this.item.metadata || {
        includeLogo: true,
        logo: "",
      }

      this.includeLogo = this.item.metadata ? this.item.metadata.include_logo : false
    }

    if (this.item.landing_page) {
      this.landingPage = "https://kadonation.com/voucher/" + this.item.landing_page.slug
    }

    // set recipients based on data from order item messages
    if (this.recipients.length === 0) {
      this.setUpRecipients()
    }
    this.$store.commit("product/setGift", {
      ...this.item.gift,
      ...(this.item.option && { options: [this.item.option] }),
    })
    if (this.item.option) this.$store.commit("product/setOptions", [this.item.option])

    this.$store.commit("product/setSelectedOptionId", this.item.option?.id)
    this.$store.commit("product/updateDirectlyToRecipient", this.directlyToRecipient)
    this.$store.commit("product/setSettings", this.settings)
    this.setState({
      attribute: "homeDelivery",
      value: !this.isDigital && this.directlyToRecipient,
    })
  },

  computed: {
    ...mapState("product", [
      "excelUploadStatus",
      "excelErrors",
      "isShowingSlideOver",
      // TODO:: Below state doesn't seem to be found in the product store!
      "showingModals",
    ]),
    ...mapState("sharedOrder", [
      "signedQuotation",
      "settings",
      "orderItems",
      "order",
      "removedMessages",
    ]),
    ...mapGetters("sharedOrder", ["personalizingItem", "personalizationDataForCurrentItem"]),
    ...mapState("recipients", ["recipients"]),
    ...mapGetters("recipients", ["excelRecipientList"]),
    directlyToRecipient() {
      return this.item.is_directly_to_recipient
    },
    isDigital() {
      // Email Sendable Kadonation Vouchers
      return ["digital"].includes(this.item.option?.kadonation_voucher_type)
    },
    item() {
      return this.personalizingItem
    },
    isExcel() {
      return this.recipientType === "excel"
    },
    isEmployeeList() {
      return this.recipientType === "employees"
    },
    gift() {
      return this.item.gift.default ?? this.item.gift
    },
    hasVoucher() {
      return !!(
        this.gift.is_kadonation_voucher || this.gift.is_send_kadonation_voucher_to_vendor_enabled
      )
    },
    hasGreetingCard() {
      return !!(
        this.gift.kadonation_product_type === "kadonation-greeting-card" ||
        this.gift.is_send_kadonation_greeting_cards_to_vendor_enabled
      )
    },
    firstFourQuantities() {
      return Object.keys(this.item.quantities)
        .filter((key, idx) => idx < 4)
        .map(key => ({ value: key, quantity: this.item.quantities[key] }))
    },
    orderId() {
      return this.item.order_id
    },
    handlingFee() {
      const fee = parseFloat(this.item.handling_fee || 0)
      const discount = parseFloat(this.item.handling_fee_discount || 0)

      return {
        fee,
        discount: discount && fee > 0 ? fee - fee * discount : 0,
      }
    },
    price() {
      return this.item.messages.map(({ value }) => parseFloat(value)).reduce((a, b) => a + b, 0)
    },
    logoImage() {
      return this.logoFilePreview || this.metadata.logo || this.logo || null
    },
    canDeleteOrderItemLogo() {
      return this.metadata.logo && !this.logoFilePreview
    },
    deliveryAddress() {
      if (!this.order.address) return ""
      const { address } = this.order
      return `${address.street} ${address.number}${address.bus ? "/" + address.bus : ""}, ${
        address.zip_code
      } ${address.city}`
    },
    hasExampleForPreview() {
      return this.hasVoucher || this.hasGreetingCard
    },
    generatePreviewApiUrl() {
      if (this.hasVoucher) {
        return this.zRoute("generate-sample.voucher")
      } else if (this.hasGreetingCard) {
        return this.zRoute("generate-sample.greeting-card")
      }
      return ""
    },
    generatedPDFs() {
      if (this.gift.kadonation_product_type === "kadonation-greeting-card") {
        return [
          {
            key: "a6_card_front_back",
            file: this.exampleVouchers["a6_card_front_back"],
          },
        ]
      }
      if (this.gift.is_send_kadonation_greeting_cards_to_vendor_enabled) {
        return [
          {
            key: "digital",
            file: this.exampleVouchers["digital"],
          },
        ]
      }
      if (this.hasVoucher) {
        const voucherTypeToPDFMap = {
          box: ["card_front_back"],
          card: ["card_front_back"],
          digital: ["digital_voucher"],
          envelope: ["card_front_back"],
          "digital-box": ["card_front_back", "digital_voucher"],
          "digital-card": ["card_front_back", "digital_voucher"],
          sleeve: ["card_front_back", "sleeve_external"],
          "digital-envelope": ["card_front_back", "envelope", "digital_voucher"],
          "digital-sleeve": ["digital_voucher", "card_front_back", "sleeve_external"],
        }

        const type = this.item.option?.kadonation_voucher_type || this.gift.kadonation_voucher_type
        if (!type) return []

        return voucherTypeToPDFMap[type].map(key => ({
          key,
          file: this.exampleVouchers[key],
        }))
      }
      return []
    },
    validationErrors() {
      return Object.entries(this.validationResponse)
        .filter(entry => entry[1])
        .map(entry => ({
          row: entry[0],
          error: entry[1],
        }))
    },
  },

  watch: {
    logoFile(value) {
      if (!value) {
        this.logoFilePreview = ""
        return
      }

      const reader = new FileReader()
      reader.onload = () => {
        this.logoFilePreview = reader.result
      }
      reader.readAsDataURL(value)
    },
  },

  methods: {
    ...mapMutations("sharedOrder", ["setState", "setPersonalizationData"]),
    ...mapMutations("recipients", ["reset", "setRecipients"]),
    ...mapMutations("product", ["setExcelUploadStatus", "setExcelErrors"]),
    getMessages() {
      return this.recipients.map(recipient => {
        recipient.price = parseInt(recipient.price)
        if (this.isDigital && this.directlyToRecipient) {
          recipient.delivery_at = `${recipient.delivery_date} ${
            recipient.delivery_time || ""
          }`.trim()
        }

        if (!isNaN(recipient.id)) return recipient

        delete recipient.id
        return recipient
      })
    },
    async uploadLogo(formData) {
      try {
        const res = await httpClient.post(
          this.zRoute("shared-order.set-logo", { orderItem: this.item.id }),
          formData,
          {
            headers: {
              enctype: "mutlipart/form-data",
            },
          },
        )
        return res
      } catch (err) {
        let message = err.response?.data?.errors?.logo_file?.[0] || err.response?.data?.message
        if (!message) {
          captureError(err)
        }
        errorToast({
          message: message || "Logo file is not supported.",
        })
        return false
      }
    },
    async deleteLogo() {
      try {
        await httpClient.delete(
          this.zRoute("shared-order.delete-logo", { orderItem: this.item.id }),
        )

        this.metadata = {
          include_logo: true,
          logo: "",
        }

        successToast({ message: "Logo deleted successfully." })

        return true
      } catch (err) {
        let message = err.response?.data?.message
        if (!message) {
          captureError(err)
        }
        errorToast({
          message: message || "Unable to delete logo file.",
        })
        return false
      }
    },
    async updateOrderItem(uploadLogoRes) {
      try {
        await httpClient.post(
          this.zRoute("shared-order.update-order-item", { orderItem: this.item.id }),
          {
            overwrite: this.overwrite,
            include_logo: this.includeLogo,
            excel_path: this.excelPath,
            removed_messages: this.removedMessages,
            ...(uploadLogoRes && {
              logo: uploadLogoRes.data.logo,
            }),
          },
        )
        return true
      } catch (err) {
        errorToast({
          message:
            err.response?.data?.message ||
            "An error has occurred when trying to save the personalization data for the order item.",
        })
        if (!err.response?.data?.message) {
          captureError(err)
        }
        return false
      }
    },
    async updateOrderItemMessages() {
      const messages = this.getMessages()
      const total_count = messages.length

      const promises = _.chunk(messages, 500).map(chunk => {
        let payload = new FormData()
        payload.append("total_count", total_count)
        payload.append("messages", JSON.stringify(chunk))

        return httpClient.post(
          this.zRoute("shared-order.update-order-item-messages", { orderItem: this.item.id }),
          payload,
          {
            headers: {
              enctype: "mutlipart/form-data",
            },
          },
        )
      })
      try {
        await Promise.all(promises)
        successToast({ message: "Personalization data saved successfully." })
        setTimeout(() => window.location.reload(), 1000)
      } catch (err) {
        captureError(err)
        errorToast({
          message:
            "An error has occurred when trying to save the personalization messages for the order item.",
        })

        if (err.response?.data?.errors) {
          const validationResponse = messages.map(() => null)
          Object.entries(err.response.data.errors).forEach(error => {
            if (error[0].startsWith("messages.")) {
              const split = error[0].split(".")
              validationResponse[split[1]] = { [split[2]]: error[1][0] }
            }
          })

          this.$refs["recipient-selector"].setValidationResponse(validationResponse)
          this.validationResponse = validationResponse
        }
      }
    },
    async save() {
      this.working = true
      this.$refs["recipient-selector"].setValidationResponse([])
      this.validationResponse = []

      let res = null

      if (this.logoFile && this.includeLogo) {
        const fd = new FormData()
        fd.append("logo_file", this.logoFile)

        res = await this.uploadLogo(fd)
        if (!res) {
          this.working = false
          return
        }
      }

      const success = await this.updateOrderItem(res)
      if (success) await this.updateOrderItemMessages()

      this.working = false
    },
    handleCancel() {
      // warn the user if there are any unsaved changes

      // clear the recipients store in preparation for the next order item
      // to be personalized
      this.reset()

      this.$emit("cancel-personalizing")
    },
    handleLogoUpload(file) {
      this.logoFile = file
    },
    async handleExcelUpload(file) {
      if (!file) {
        this.$store.commit("product/setUploadedExcel", null)
        return
      }

      this.setExcelUploadStatus(null)
      this.setExcelErrors([])
      this.validationResponse = []
      this.$refs["recipient-selector"].setValidationResponse([])

      // this.$store.commit("product/setSpecificErrorNull", "excelFile")
      this.$store.commit("product/setUploadedExcel", file)
      this.isImportingExcel = true
      this.showUploadSuccessMessage = false
      const fd = new FormData()
      fd.append("excelFile", file)
      fd.append("giftId", this.item.gift.id)
      fd.append("occasionId", this.item.occasion.id)
      fd.append("optionId", this.item.option?.id)
      fd.append("tenant", this.settings.tenant_id)
      fd.append("has_greeting_card", this.item.has_greeting_card ? 1 : 0)
      if (this.directlyToRecipient) fd.append("is_directly", 1)
      try {
        const response = await httpClient.post(this.zRoute("excel.import"), fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        this.$store.commit(
          "recipients/setRecipients",
          response.data.items.map(data => {
            let deliveryDate = null

            if (data.delivery_date) {
              try {
                deliveryDate = DateTime.fromFormat(data.delivery_date, "yyyy-LL-dd").toFormat(
                  this.dateTimeFormat.date,
                )
              } catch (err) {
                console.warn("Error parsing delivery date!", err)
              }
            }

            return {
              ...data,
              id: uuid(),
              excel: true,
              delivery_date: deliveryDate,
              delivery_at: `${deliveryDate} ${data.delivery_time || ""}`.trim(),
              thankyou_video: data.thankyou_video || null,
            }
          }),
        )
        this.setExcelUploadStatus(true)
        this.isExcelList = true
        this.showUploadSuccessMessage = true
        this.overwrite = true
        this.excelPath = response.data.excel_file
      } catch (err) {
        let errors = err.response?.data?.errors?.excelErrors
        this.setExcelErrors(errors)
        this.setExcelUploadStatus(false)
      } finally {
        this.isImportingExcel = false
      }
    },

    setUpRecipients() {
      this.setRecipients(
        this.item.messages.map(message => {
          return {
            price: message.value || "",
            from: message.from || "",
            to: message.to || "",
            message: message.message || "",
            detailed_reference: message.detailed_reference || "",
            locale: message.locale || "",
            email: message.email || "",
            delivery_date: message.delivery_date
              ? DateTime.fromFormat(message.delivery_date, "yyyy-LL-dd").toFormat(
                  this.dateTimeFormat.date,
                )
              : "",
            delivery_time: message.delivery_time
              ? DateTime.fromFormat(message.delivery_time, "TT").toFormat("T")
              : "",
            thankyou_video: message.thank_you_video ? message.thank_you_video.filename : null,
            customer_fields: message.customer_fields,
            recipient_first_name: message.recipient_first_name || "",
            recipient_last_name: message.recipient_last_name || "",
            street: (message.address && message.address.street) || "",
            street_number: (message.address && message.address.number) || "",
            bus: (message.address && message.address.bus) || "",
            zipcode: (message.address && message.address.zip_code) || "",
            city: (message.address && message.address.city) || "",
            country: (message.address && message.address.country) || "",
            id: message.id,
            address_id: message.address_id || "",
            employee_id: message.employee_id || null,
            uuid: message.uuid,
            address_uuid: (message.address && message.address.uuid) || "",
          }
        }),
      )
    },

    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    showToast(text, success = false) {
      if (success) {
        successToast({ message: this.t(text) })
      } else {
        errorToast({ message: this.t(text) })
      }
    },
    getSampleRecipient() {
      if (!this.recipients.length > 0) {
        this.showToast("shared-order-approval.minimum_one_recipient")
        return null
      }

      // Get the last recipient for preview. Make sure that the previewed item logic is
      // the same with the admin panel.
      return this.recipients[this.recipients.length - 1]
    },
    getSampleEmployeeData(recipient) {
      const sampleRecipient = recipient || this.getSampleRecipient()
      if (!sampleRecipient) {
        return
      }

      const metadata = {
        ...(sampleRecipient.metadata && sampleRecipient.metadata),
        ...(sampleRecipient.customer_fields && sampleRecipient.customer_fields),
      }

      return {
        from: sampleRecipient.from,
        to: sampleRecipient.to,
        message: sampleRecipient.message,
        locale: sampleRecipient.locale || "en",
        amount: sampleRecipient.price,
        orderItem: this.item.id,
        logo: this.includeLogo ? this.logoImage : null,
        ...(Object.keys(metadata).length > 0 && { metadata }),
      }
    },
    getSampleEmailData() {
      const sampleRecipient = this.getSampleRecipient()
      if (!sampleRecipient) {
        return
      }

      if (!this.validateEmail(this.previewEmail)) {
        this.showToast("shared-order-approval.provide_valid_email")
        return
      }

      const metadata = {
        ...(sampleRecipient.metadata && sampleRecipient.metadata),
        ...(sampleRecipient.customer_fields && sampleRecipient.customer_fields),
      }

      return {
        from: sampleRecipient.from,
        to: sampleRecipient.to,
        message: sampleRecipient.message,
        amount: sampleRecipient.price,
        email: this.previewEmail,
        orderItem: this.item.id,
        logo: this.includeLogo ? this.logoImage : null,
        ...(Object.keys(metadata).length > 0 && { metadata }),
      }
    },
    generateExampleVoucher(recipient) {
      if (this.isLoadingExampleVoucher) return
      this.exampleVoucherRecipient = null

      const data = this.getSampleEmployeeData(recipient)
      if (!data) return

      this.isLoadingExampleVoucher = true
      this.exampleVoucherRecipient = data

      this.previewModalOpen = !this.previewModalOpen

      httpClient
        .post(this.generatePreviewApiUrl, data)
        .then(({ data }) => {
          this.exampleVouchers = data?.files || data
          this.activePDF = this.generatedPDFs[0]?.file
          this.isLoadingExampleVoucher = false
        })
        .catch(error => {
          this.isLoadingExampleVoucher = false
          this.previewModalOpen = !this.previewModalOpen
          if (error.response?.status == 422) {
            const message = Object.values(error.response.data.errors)[0]
            message && errorToast({ message: message[0] })
          } else {
            errorToast({ message: error.response.data.message })
          }
        })
    },
    sendPreviewEmail() {
      if (this.isSendingPreviewEmail) return

      this.isSendingPreviewEmail = true

      const data = this.getSampleEmailData()
      if (!data) return

      httpClient
        .post(this.zRoute("generate-sample.send-email"), data)
        .then(() => {
          this.showToast("shared-order-approval.preview_email_sent", true)
        })
        .catch(error => {
          const message = Object.values(error.response.data.errors)[0]
          message && errorToast({ message: message[0] })
        })
        .finally(() => (this.isSendingPreviewEmail = false))
    },
    closePreviewModal() {
      this.previewModalOpen = false
    },
  },
}
</script>
