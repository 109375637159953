<template>
  <div>
    <template v-if="!(multipleSelected || isExcel) && employeesEnabled">
      <p class="text-sm" v-html="$t('orders.employee_list_info_message')"></p>
      <div class="grid grid-cols-5 gap-3 mt-4">
        <OnClickOutside
          ref="searchEmployees"
          class="relative col-span-3"
          @trigger="closeShowingResults"
        >
          <div class="relative">
            <input
              type="text"
              :placeholder="$t('orders.search')"
              class="w-full px-5 py-3 text-xs transition border border-blue-200 rounded focus:ring-blue-200"
              @focus="isShowingResults = true"
              @input="search"
            />
            <span class="absolute inset-y-0 right-0 flex items-center px-3">
              <SearchIcon class="w-4 h-4 text-primary" />
            </span>
          </div>
          <slide-in dir="down">
            <div
              v-if="isShowingResults"
              class="absolute inset-x-0 z-50 py-2 mt-1 text-xs bg-white border border-blue-200 rounded shadow"
            >
              <div class="px-3 pt-1 pb-3 border-b">
                <button
                  type="button"
                  class="px-3 py-2 font-bold text-primary uppercase transition rounded focus:outline-none hover:bg-pink-200"
                  @click="addAllEmployees"
                  v-html="$t('orders.add_all_employees')"
                ></button>
              </div>
              <ul
                v-if="searchResults.length"
                class="h-full px-3 mt-4 space-y-1 overflow-y-auto"
                style="max-height: 330px"
              >
                <li
                  v-for="employee in searchResults"
                  :key="`result-${employee.id}`"
                  class="flex items-center justify-between p-3 font-semibold transition rounded cursor-pointer hover:bg-gray-150"
                  @click="toggleEmployee(employee)"
                >
                  <span>{{ `${employee.first_name} ${employee.last_name}` }}</span>
                  <span class="flex space-x-6 text-primary">
                    <span v-if="employee.group" class="text-xs uppercase">{{
                      employee.group.name
                    }}</span>
                    <span class="w-4">
                      <CheckCircleSolidIcon
                        v-if="employeeIds.includes(employee.id)"
                        class="w-4 h-4 text-primary"
                      />
                    </span>
                  </span>
                </li>
              </ul>
              <div v-else class="p-3">
                <p><em v-html="$t('orders.empty_search_result')"></em></p>
              </div>
            </div>
          </slide-in>
        </OnClickOutside>
        <div class="col-span-2">
          <TeamSelector class="bg-white" />
        </div>
      </div>
    </template>

    <section v-if="multipleSelected" class="px-5 py-6 pb-1 bg-white border border-gray-200 rounded">
      <h3 class="heading-3 font-bold text-gray-500">
        {{
          allSelected
            ? $t("orders.all_recipients_selected", {
                count: selectedRecipients.length,
              })
            : $t("orders.recipients_selected", {
                count: selectedRecipients.length,
              })
        }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        {{ $t("orders.mass_update_explainer") }}
      </p>
      <p></p>
      <div class="flex items-center mt-4 space-x-3">
        <!-- From Input -->
        <TextInput
          name="from"
          :label="$t('forms.from')"
          :placeholder="$t('forms.full_name')"
          class="relative w-full max-w-md"
          @update:model-value="
            massUpdate({
              attribute: 'from',
              value: $event,
            })
          "
        />
        <!-- Amount Input -->
        <div v-if="!hasFixedPrice" class="relative w-full max-w-md">
          <MonetaryInput
            name="group-amount"
            :label="$t('forms.amount')"
            placeholder="40"
            required
            class="relative w-full max-w-md"
            @update:model-value="
              massUpdate({
                attribute: 'price',
                value: $event,
              })
            "
          />
        </div>
        <!-- Language Selector -->
        <SelectHtml
          :label="$t('forms.locale')"
          class="relative w-full max-w-md"
          :placeholder="$t('forms.choose_language')"
          required
          :options="languages"
          @update:model-value="
            $event => {
              massUpdate({
                attribute: 'locale',
                value: $event,
              })
            }
          "
        />

        <!-- Message Input -->
        <mass-update-message-input />

        <!-- Delivery Date -->
        <DatePicker
          v-show="directlyToRecipient && (isDigital || gift.is_enabled_for_future_delivery)"
          class="relative w-full max-w-md"
          style="min-width: 9.2rem"
          type="date"
          placeholder="DD-MM-YYYY"
          :label="$t('forms.delivery_date')"
          :min-date="today"
          :format="dateTimeFormat.date"
          :disabled-dates="holidays"
          :disabled-week-days="disabledWeekDays"
          @input="massUpdate({ attribute: 'delivery_date', value: $event })"
        />
        <!-- Delivery Time -->
        <DatePicker
          v-show="directlyToRecipient && isDigital"
          class="relative w-full max-w-md"
          type="time"
          style="min-width: 6.5rem"
          placeholder="HH:MM"
          :label="$t('forms.delivery_time')"
          :format="dateTimeFormat.time"
          :minute-step="15"
          @input="massUpdate({ attribute: 'delivery_time', value: $event })"
        />
        <div>
          <div class="relative flex items-center">
            <TertiaryButton compact @click="isBulkVideoModalShown = true">
              <UploadSolidIcon class="flex-shrink-0 w-4 h-4" />
              <span class="whitespace-nowrap">{{ $t("orders.video") }}</span>
            </TertiaryButton>
            <ModalDefault
              :show="isBulkVideoModalShown"
              :title="$t('orders.video')"
              hide-icon
              :secondary-button="{ text: $t('general.close') }"
              @secondary-click="isBulkVideoModalShown = false"
              @close="isBulkVideoModalShown = false"
            >
              <VideoUpload
                ref="uploadVideo"
                :model-value="bulkVideo"
                class="w-full"
                dropzone-classes="py-4 px-2 w-full"
                @input="handleMassVideoUpload"
              />
            </ModalDefault>
          </div>
        </div>
      </div>
    </section>

    <div v-if="pageData.length === 0 && !isAddingNewRecipient" class="py-12">
      <div class="flex flex-col items-center space-y-8">
        <div class="space-y-2 text-center">
          <p v-html="$t('orders.search_and_select_info')"></p>
          <template>
            <p class="text-xs text-gray-300 uppercase">
              {{ $t("kudos.or") }}
            </p>
            <p class="flex justify-center">
              <button
                class="flex items-center space-x-2 text-primary"
                @click="startAddingRecipient"
              >
                <PlusBigIcon class="w-4 h-4" />

                <AddNewRecipient @recipient-added="handleRecipientAdded"></AddNewRecipient>
              </button>
            </p>
          </template>
        </div>
        <img :src="teamImage" alt="Search and select employees from the Employee List" />
      </div>
    </div>

    <template v-else>
      <!-- Table buttons -->
      <div v-if="!readOnly" class="mt-6 flex space-x-4 justify-between">
        <!-- Select/Deselect all -->
        <CheckBox
          :label="
            multipleSelected
              ? $t('orders.deselect_all_recepients')
              : $t('orders.select_all_recepients')
          "
          :model-value="multipleSelected"
          class="items-center"
          @update:model-value="selectAll"
        />
        <AddNewRecipient v-if="!isExcel" @recipient-added="handleRecipientAdded"></AddNewRecipient>
      </div>
      <!-- Table -->
      <div class="flex flex-col mt-2 border rounded">
        <div class="inline-block min-w-full align-middle">
          <div
            id="tableWrapper"
            ref="tableWrapper"
            class="overflow-auto border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <RecipientSelectorTableHeader
                :read-only="readOnly"
                :customer-fields="customerFields"
                :direct="!!directlyToRecipient"
                :home-delivery="!!isNonDigitalDirectHomeDelivery"
                :detailed-reference="settings.detailed_invoice === true"
                :fixed-price="hasFixedPrice"
                :is-vendor-gift="isVendorGift"
              />
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-if="readOnly">
                  <ReadOnlyRecipientRow
                    v-for="(recipient, index) in pageData"
                    :key="recipient.id"
                    :recipient="recipient"
                    :index="index"
                    :customer-fields="customerFields"
                    :can-manage-employees="canManageEmployees"
                  />
                </template>
                <template v-else>
                  <RecipientRow
                    v-for="(recipient, index) in pageData"
                    :key="recipient.id"
                    :recipient="recipient"
                    :index="index"
                    :validation-error="
                      showValidationErrors
                        ? validationErrors[index + (currentPage - 1) * perPage]
                        : null
                    "
                    :customer-fields="customerFields"
                    :can-manage-employees="canManageEmployees"
                    :holidays="holidays"
                    :has-example-for-preview="hasExampleForPreview"
                    @showPreview="$emit('showPreview', recipient)"
                  />
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- Pagination -->
      <div v-if="totalPages > 1" class="flex items-center justify-center mt-6 space-x-3 text-sm">
        <button
          type="button"
          class="block p-1 disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="!hasPreviousPage"
          @click="previousPage"
        >
          &lt;
        </button>
        <span class="text-primary">{{ currentPage }}</span>
        <span>
          {{ $t("general.of") }}
        </span>
        <span>{{ totalPages }}</span>
        <button
          type="button"
          class="block p-1 disabled:cursor-not-allowed disabled:opacity-50"
          :disabled="!hasNextPage"
          @click="nextPage"
        >
          &gt;
        </button>
      </div>
      <!-- Required fields info -->
      <div class="mt-6 flex items-center space-x-1 text-xs">
        <InfoCircleSolidIcon class="w-5 h-5 text-gray-400" />
        <span> {{ $t("orders.these_are_required_fields") }} {{ requiredFields }}. </span>
        <span v-if="isTableHorizontallyScrollable">
          {{ $t("orders.scroll_horizontally_to_show_more_fields") }}
        </span>
      </div>
      <!-- Errors on Page -->
      <div
        v-if="showValidationErrors && pagesHavingError.length"
        class="mt-4 p-4 inline-flex items-center space-x-2 text-sm text-red-400 bg-red-50 rounded"
      >
        <XCircleSolidIcon class="w-5 h-5 text-red-300" />
        <p>
          {{ $t("errors.there_are_errors_on") }}
          <template v-for="(page, pageIndex) in pagesHavingError" :key="page">
            <ActionLink @click="currentPage = page">
              {{ $t("general.page") }} {{ page }}
            </ActionLink>
            <span v-if="pageIndex < pagesHavingError.length - 1" :key="`space${page}`">, </span>
          </template>
        </p>
      </div>

      <!-- End Table -->
    </template>

    <div v-if="!readOnly" class="mt-10 space-x-4">
      <PrimaryButton :busy="working" :disabled="working" @click="confirmRecipientSelection">{{
        $t("general.save")
      }}</PrimaryButton>
      <TertiaryButton :disabled="working" @click="$emit('cancel')">{{
        $t("general.cancel")
      }}</TertiaryButton>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"
import { mapGetters, mapState, mapMutations, mapActions } from "vuex"
import { OnClickOutside } from "@vueuse/components"
import ModalDefault from "@/components/elements/modals/ModalDefault.vue"
import { DateTime } from "luxon"
import _ from "lodash"
import SelectHtml from "@/components/elements/inputs/SelectHtml.vue"
import RecipientRow from "./RecipientRow.vue"
import AddNewRecipient from "./AddNewRecipient.vue"
import CheckBox from "@/components/elements/inputs/CheckBox.vue"
import ReadOnlyRecipientRow from "./ReadOnlyRecipientRow.vue"
import RecipientSelectorTableHeader from "./RecipientSelectorTableHeader.vue"
import SlideIn from "@/components/transitions/SlideIn.vue"
import TeamSelector from "@/components/employees/TeamSelector.vue"
import VideoUpload from "@/components/employees/VideoUpload.vue"
import DatePicker from "@/components/forms/DatePicker.vue"
import PrimaryButton from "@/components/elements/buttons/PrimaryButton.vue"
import TertiaryButton from "@/components/elements/buttons/TertiaryButton.vue"
import MassUpdateMessageInput from "@/components/recipients/MassUpdateMessageInput.vue"
import teamImage from "../../../assets/images/team.png"

import {
  PlusBigIcon,
  SearchIcon,
  CheckCircleSolidIcon,
  XCircleSolidIcon,
  UploadSolidIcon,
  InfoCircleSolidIcon,
} from "@/components/icons"
import { havePermission } from "@/helpers/permission-helpers"
import { errorToast } from "@/helpers/toasts"
import httpClient from "@/helpers/http"
import { formatDateFromISO } from "@/helpers/date-helpers"
import Holidays from "date-holidays"
import MonetaryInput from "@/components/elements/inputs/MonetaryInput.vue"
import TextInput from "@/components/elements/inputs/TextInput.vue"
import ActionLink from "@/components/elements/links/ActionLink.vue"

export default {
  components: {
    SlideIn,
    CheckBox,
    ModalDefault,
    SelectHtml,
    DatePicker,
    SearchIcon,
    PlusBigIcon,
    VideoUpload,
    RecipientRow,
    TeamSelector,
    PrimaryButton,
    OnClickOutside,
    AddNewRecipient,
    TertiaryButton,
    UploadSolidIcon,
    XCircleSolidIcon,
    InfoCircleSolidIcon,
    ReadOnlyRecipientRow,
    CheckCircleSolidIcon,
    MassUpdateMessageInput,
    RecipientSelectorTableHeader,
    MonetaryInput,
    TextInput,
    ActionLink,
  },

  props: {
    excel: {
      type: Boolean,
      default: false,
    },
    readOnly: Boolean,
    employeesEnabled: {
      type: Boolean,
      default: false,
    },
    working: Boolean,
    hasExampleForPreview: Boolean,
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },

  data() {
    const currentPage = 1

    return {
      isShowingResults: false,
      isAddingNewRecipient: false,
      searchResults: [],
      currentPage,
      perPage: 10,
      isTableHorizontallyScrollable: false,
      validationResponse: null,
      showValidationErrors: false,
      massUpdateMessageTextAreaFocused: false,
      messageSlideOverOpen: false,
      isBulkVideoModalShown: false,
      bulkVideo: null,
      teamImage,
    }
  },
  computed: {
    ...mapState("recipients", ["selectedRecipients", "recipients"]),
    ...mapState("product", ["directlyToRecipient", "locales", "settings"]),
    ...mapGetters("recipients", ["recipientList", "excelRecipientList"]),
    ...mapGetters("product", [
      "isKadonationVoucher",
      "isVendorGift",
      "hasFixedPrice",
      "fixedPrice",
      "isDigital",
      "isNonDigitalDirectHomeDelivery",
      "emailAddressRequired",
    ]),
    gift() {
      return this.$store.state.product.gift.default ?? this.$store.state.product.gift
    },
    languages() {
      return this.locales.length ? this.locales : this.$store.state.sharedOrder.locales
    },
    employeeIds() {
      return this.recipientData.map(recipient => recipient.employee_id)
    },
    recipientData() {
      return this.isExcel ? this.excelRecipientList : this.recipientList
    },
    requiredFields() {
      const fields = []
      if (!this.directlyToRecipient) {
        if (!this.hasFixedPrice) {
          fields.push(this.t("forms.amount"))
        }
        fields.push(this.t("forms.language"))
      } else {
        // Direct to recipient
        if (this.isNonDigitalDirectHomeDelivery) {
          fields.push(this.t("forms.recipient_first_name"))
          fields.push(this.t("forms.recipient_last_name"))
        }
        if (this.emailAddressRequired) {
          fields.push(this.t("forms.email"))
        }
        if (!this.hasFixedPrice) {
          fields.push(this.t("forms.amount"))
        }
        fields.push(this.t("forms.language"))
        if (this.isNonDigitalDirectHomeDelivery) {
          fields.push(this.t("forms.address"))
        } else {
          // if digital
          fields.push(this.t("forms.delivery_date"))
          fields.push(this.t("forms.delivery_time"))
        }
      }

      if (fields.length > 1) {
        return `${fields.slice(0, fields.length - 1).join(", ")} & ${fields[fields.length - 1]}`
      } else if (fields.length === 1) {
        return fields[0]
      }
      return ""
    },
    validationErrors() {
      const errors = this.recipientData.map(recipient => {
        return this.validateRecipient(recipient)
      })
      return errors
    },
    pagesHavingError() {
      const pages = []
      this.validationErrors.forEach((data, index) => {
        const pageNo = Math.floor(index / this.perPage) + 1
        if (data && !pages.includes(pageNo)) {
          // if there is an error add it to pages
          pages.push(pageNo)
        }
      })
      return pages
    },
    totalPages() {
      return Math.ceil(this.recipientData.length / this.perPage)
    },
    hasPreviousPage() {
      return this.currentPage > 1
    },
    hasNextPage() {
      return this.currentPage < this.totalPages
    },
    pageData() {
      const start = this.currentPage * this.perPage - this.perPage

      return this.recipientData.slice(start, start + this.perPage)
    },
    isExcel() {
      return this.excel
    },
    multipleSelected() {
      return this.selectedRecipients.length > 1
    },
    allSelected() {
      return this.selectedRecipients.length === this.recipients.length
    },
    title() {
      return this.isExcel
        ? this.t("orders.excel_user_list_title")
        : this.t("orders.employee_list_title")
    },
    today() {
      return formatDateFromISO(new Date().toISOString, this.dateTimeFormat.date)
    },
    customerFields() {
      return [
        ...new Set(
          this.pageData.map(recipient => Object.keys(recipient.customer_fields || {})).flat(),
        ),
      ]
    },
    canManageEmployees() {
      return havePermission("manage-employees")
    },
    holidays() {
      if (this.isKadonationVoucher || !this.gift?.is_enabled_for_future_delivery) return []
      const hd = new Holidays("BE", { types: ["public"] })
      const currentYear = new Date().getFullYear()

      let holidays = []
      for (let i = currentYear; i <= currentYear + 2; i++) {
        holidays = holidays.concat(hd.getHolidays(i))
      }

      return holidays.map(day => day.date)
    },
    disabledWeekDays() {
      if (this.isKadonationVoucher || !this.gift?.is_enabled_for_future_delivery) return []

      return [0, 6]
    },
  },

  watch: {
    pageData(val) {
      if (Array.isArray(val) && val.length === 0) {
        this.previousPage()
      }
    },
  },

  methods: {
    ...mapMutations("recipients", ["removeTeams", "selectAll", "massUpdate"]),
    ...mapActions("recipients", ["addAllEmployees"]),
    handleMassVideoUpload(value) {
      this.massUpdate({ attribute: "thankyou_video", value })

      this.bulkVideo = value
    },
    handleClickAway(e) {
      if (this.$refs.searchEmployees.contains(e.target)) {
        return
      }
      this.isShowingResults = false
    },
    search: _.debounce(function (event) {
      const query = event.target.value

      if (!query) {
        this.searchResults = []
        return
      }

      httpClient
        .get(`/${this.userLang}/employees/search?q=${query}`)
        .then(response => (this.searchResults = response.data))
        .catch(err => console.log(err))
    }, 300),
    toggleEmployee(employee) {
      if (this.hasFixedPrice) employee.price = this.fixedPrice

      if (this.employeeIds.includes(employee.id)) {
        this.$store.dispatch("recipients/removeRecipientByEmployeeId", employee.id)
      } else {
        this.$store.commit("recipients/addEmployees", [employee])
      }
    },
    confirmRecipientSelection() {
      // We start showing validation errors after the user hits save for the first time
      this.showValidationErrors = true

      if (!this.validateData()) {
        errorToast({
          message: this.t("orders.errors_in_order_form"),
        })

        return
      }

      this.$store.commit("recipients/confirmList")
      this.$emit("confirm-selection")
    },
    validateData() {
      const errors = this.validationErrors.filter(error => error)
      return errors.length === 0
    },
    validateRecipient(recipient) {
      const errors = {}

      if (this.emailAddressRequired) {
        if (!recipient.email) {
          errors["email"] = this.$t("validation.required", {
            attribute: this.$t("forms.email"),
          })
        }
      }
      if (this.directlyToRecipient && !this.isNonDigitalDirectHomeDelivery) {
        if (!recipient.delivery_time) {
          errors["delivery_time"] = this.t("validation.required", {
            attribute: this.t("forms.delivery_time"),
          })
        }
        if (!recipient.delivery_date) {
          errors["delivery_date"] = this.t("validation.required", {
            attribute: this.t("forms.delivery_date"),
          })
        }
        if (recipient.delivery_date && recipient.delivery_time) {
          try {
            const now = DateTime.local()
            const time = DateTime.fromFormat(
              `${recipient.delivery_date} ${recipient.delivery_time}`,
              "dd-LL-yyyy HH:mm",
            )

            if (!time.isValid) {
              // noinspection ExceptionCaughtLocallyJS
              throw new Error()
            }

            const diff = time.diff(now, ["hours", "days"]).toObject()

            if (!time.hasSame(now, "day") && diff.days < 0) {
              errors["delivery_date"] = this.t("messages.invalid_date_in_past")
            } else if (time.hasSame(now, "day") && diff.hours < 1.01) {
              errors["delivery_time"] = this.t("messages.invalid_must_ahead_hour", { hour: 1 })
            }
          } catch (err) {
            errors["delivery_date"] = this.t("messages.invalid_datetime_format")
          }
        }
      }
      if (this.directlyToRecipient && this.isNonDigitalDirectHomeDelivery) {
        if (!recipient.recipient_first_name) {
          errors["recipient_first_name"] = this.$t("validation.required", {
            attribute: this.$t("forms.recipient_first_name"),
          })
        }
        if (!recipient.recipient_last_name) {
          errors["recipient_last_name"] = this.$t("validation.required", {
            attribute: this.$t("forms.recipient_last_name"),
          })
        }

        // KGSP-4893: ‘Recipient First Name + Recipient Last Name’ should be max 30 characters
        if (recipient.recipient_first_name.length + recipient.recipient_last_name.length > 30) {
          errors["recipient_first_name"] = this.$t(
            "validation.recipient_name_character_limit_exceeded",
            {
              attribute: this.$t("forms.recipient_first_name"),
            },
          )
          errors["recipient_last_name"] = this.$t(
            "validation.recipient_name_character_limit_exceeded",
            {
              attribute: this.$t("forms.recipient_last_name"),
            },
          )
        }
      }
      if (
        this.directlyToRecipient &&
        this.gift.is_enabled_for_future_delivery &&
        !recipient.delivery_date
      ) {
        errors["delivery_date"] = this.t("validation.required", {
          attribute: this.t("forms.delivery_date"),
        })
      }
      if (!this.hasFixedPrice) {
        if (!recipient.price || recipient.price < 1) {
          errors["price"] = this.t("validation.required", {
            attribute: this.t("forms.amount"),
          })
        }
      }
      if (!recipient.locale) {
        errors["locale"] = this.t("validation.required", {
          attribute: this.t("forms.locale"),
        })
      }
      if (
        this.isNonDigitalDirectHomeDelivery &&
        ((this.canManageEmployees && recipient.employee_id) || !recipient.employee_id)
      ) {
        if (!recipient.street) {
          errors["street"] = this.t("validation.required", {
            attribute: this.t("forms.street"),
          })
        }
        if (!recipient.street_number) {
          errors["street_number"] = this.t("validation.required", {
            attribute: this.t("forms.number"),
          })
        }
        if (recipient.street_number.length > 8) {
          errors["street_number"] = this.t("validation.max.string", {
            attribute: this.t("forms.number"),
            max: 8,
          })
        }
        if (recipient.bus.length > 4) {
          errors["bus"] = this.t("validation.max.string", {
            attribute: this.t("forms.bus"),
            max: 4,
          })
        }
        if (!recipient.zipcode) {
          errors["zipcode"] = this.t("validation.required", {
            attribute: this.t("forms.zipcode"),
          })
        }
        if (!recipient.city) {
          errors["city"] = this.t("validation.required", {
            attribute: this.t("forms.city"),
          })
        }
        if (recipient.city.length > 30) {
          errors["city"] = this.t("validation.max.string", {
            attribute: this.t("forms.city"),
            max: 30,
          })
        }
        if (!recipient.country) {
          errors["country"] = this.t("validation.required", {
            attribute: this.t("forms.country"),
          })
        }
      }

      if (
        this.isNonDigitalDirectHomeDelivery &&
        !recipient.employee_address_id &&
        !this.canManageEmployees &&
        recipient.employee_id
      ) {
        if (!recipient.employee_address_id) {
          errors["row"] = this.t("employees.missing_address_info")
        }
      }

      return Object.keys(errors).length > 0 ? errors : null
    },
    setValidationResponse(response) {
      this.validationResponse = response
    },
    startAddingRecipient() {
      this.isAddingNewRecipient = true
    },
    stopAddingEmployee() {
      this.isAddingNewRecipient = false
    },
    previousPage() {
      if (!this.hasPreviousPage) return

      this.currentPage--
    },
    nextPage() {
      if (!this.hasNextPage) return

      this.currentPage++
    },
    handleRecipientAdded() {
      this.$nextTick(() => {
        let input = document.querySelector(`#from-0`)
        if (input) input.focus()
      })
    },
    closeShowingResults() {
      this.isShowingResults = false
    },
  },
  created() {
    // if the table is horizontally scrollable we want to show some note to user
    this.checkTableHorizontallyScrollable = _.debounce(() => {
      const table = this.$refs.tableWrapper
      this.isTableHorizontallyScrollable = table && table.scrollWidth > table.clientWidth
    }, 500)
  },
  mounted() {
    // if the table is horizontally scrollable we want to show some note to user
    const showScrollHorizontallyNoteInterval = setInterval(() => {
      if (this.$refs.tableWrapper) {
        this.checkTableHorizontallyScrollable()
        clearInterval(showScrollHorizontallyNoteInterval)
      }
    }, 500)
    window.addEventListener("resize", this.checkTableHorizontallyScrollable, false)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkTableHorizontallyScrollable, false)
  },
}
</script>
